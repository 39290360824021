.content-app.#{$main-prefix}__nosotros {
  padding: 72px 0px 39px 0px;
}

.#{$main-prefix}__nosotros {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    justify-content: space-between;
    
    img.#{$main-prefix}_ {
      padding: 0;
      width: 90%;
    }
    
    h2.#{$main-prefix}_title {
      margin: 0;
      text-align: left;
    }
    
    p.#{$main-prefix}_ {
        font-size: 16px;
        margin-left: 0px;
        color: $grey-color;
        line-height: 1.5em;
        font-weight: 300;
    }
    
    p.#{$main-prefix}_.#{$main-prefix}_subtitle-nosotros {
      font-weight: 400;
      font-size: 20px;
    }

    p.#{$main-prefix}_ {
      margin: 0;

      & em {
        font-weight: 400;
      } 
    }

    .#{$main-prefix}__nosotros-left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 50px;

      &.#{$main-prefix}_img-rigth {
        justify-content: end;
      }
    }
}


@media (max-width: 900px) {
  .#{$main-prefix}__nosotros {
    flex-wrap: wrap;
  }

  .expreso-el-vasquito-content-buttons-sucursales {
    max-width: 100%;
    width: 100%;
    flex-wrap: wrap;
  }

  .content-app.expreso-el-vasquito__nosotros {
    display: flex;
    width: 85%;
    margin: 0 auto;

    p.expreso-el-vasquito_.expreso-el-vasquito_subtitle-nosotros {
      margin: 10px 0;
    }

    .expreso-el-vasquito__nosotros-left.nosotros {
      order: 1;
      margin-top: -70px;
      h2.expreso-el-vasquito_title {
        margin-bottom: 20px;
      }
    }

    .expreso-el-vasquito__nosotros-left.first-img {
      order: 2;
    }

    .expreso-el-vasquito__nosotros-left.three {
      order: 3
    }

    .expreso-el-vasquito__nosotros-left.expreso-el-vasquito_img-rigth {
      order: 4;
    }
  }

  img.expreso-el-vasquito_ {
    width: 100%!important;
  }
}