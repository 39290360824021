@import '../_variable.scss';

header.content-app {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
    max-width: 100%;
    z-index: 91;
    
    .expreso-el-vasquito__content {
        width: 1200px;
        height: 64px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
    }

    img.img-logo {
        height: 50px;
        width: auto;
        display: block;
        padding-top: 6px;
    }

    .navbar-content {
        height: 64px;

        a.link-menu {
            display: inline-block;
            text-align: center;
            padding: 23px;
            text-decoration: none;
            color: grey;
            
            &:hover {
                text-align: center;
                background-color: $second-blue;
                color: white;
            }
        }

        .link-menu {
            display: inline-block;
            text-align: center;
            padding: 23px;
            text-decoration: none;
            color: grey;

            &:hover {
                text-align: center;
                background-color: $second-blue;
                color: white;
            }
        }
    }
}
.content-app.expreso-el-vasquito__sucursales {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 150px;
}

.card_sucursales {
    width: 310px;
    display: flex;
    flex-direction: column;
    margin: 49px 0px 0px 0px;
    background: $grey-light-color;
    border-radius: 30px 30px 0px 0px;
    padding: 0px 0px 20px 0px;
}
img.expreso-el-vasquito__card-img {
    border-radius: 30px 30px 0px 0px;
}

span.name {
  color: $main-blue;
  margin: 14px 0px 10px 14px;
  font-weight: 500;
  font-size: 20px;
}
span.descripcion {
    font-weight: 300;
    margin: 9px 0 0 14px;
}
span.mail {
    text-decoration: underline;
    color: #8ecbea;
    margin: 9px 0 0 14px;
}
.button_sucursales {
  height: 25px;
  padding-top: 5px;
  width: 90px;
  background-color: $main-blue;
  border: none;
  color: white;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  margin: 15px auto 0;
  cursor: pointer;
  text-align: center;
  vertical-align: center;
}

.sucursales {
  h2.expreso-el-vasquito_title {
    margin: 50px 0!important;
    text-align: center;
  }
}


@media (max-width: 900px) {
  .content-app.sucursales {
    .content-app.expreso-el-vasquito__sucursales {
      padding-bottom: 25px;
    }
    .card_sucursales {
      margin: 10px 0px 0px 0px;
    }
  }
}