.content_contacto  {
    background: linear-gradient(133deg, rgba(240,166,124,1) 0%, rgba(120,104,239,1) 100%);
    padding: 100px;
}

.expreso-el-vasquito__contacto-card  {
  margin: 0 auto;
  width: 530px;
  height: 350px;
  border-radius: 50px;
  padding: 40px;
  box-shadow: 0 0 3px 0;
  border-radius: 30px;
  background-color: white;
}
h2#contacto {
  margin: 0px 0px 14px 0px;
  text-align: center;
  font-weight: 300;
}
form {
  display: flex;
  flex-wrap: wrap;
}

input {
  margin: 8px 0;
  border-radius: 10px;
  width: 45%;
  height: 34px;
  border: 1px solid $grey-color;
}
select {
  width: 47%;
  height: 41px;
  border-radius: 50px;
  margin: 20px 0 0 12px;
}

textarea {
  width: 98%;
  height: 100px;
  border-radius: 22px;
  padding: 10px;
}

.content-form.destinatario {
  margin-bottom: 15px;
}

@media (max-width: 800px) {
  .content-app {
    max-width: 100%;
    width: 100%;
  }
  .content_contacto {
    height: 600px;
    padding: 10px;
  }

  .expreso-el-vasquito__contacto-card {
    margin: 20px auto;
    width: 88%;
    padding: 20px;
    max-width: 88%;
    height: 87%;
    input.expreso-el-vasquito--input {
      width: 98%!important;
      height: 50px;
    }
    select {
      height: 50px!important;
      width: 100%!important;
    }
    textarea {
      width: 95%!important;
    }
    input[type=submit] {
      font-size: 24px;
    }
  }

  .content-form.destinatario {
    flex-direction: column;
    width: 100%;
  }
}