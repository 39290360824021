@import '../../_variable.scss';

.mobile-menu-content {
  width: 100%;
  // .mobile-img-logo {
  //   width: 200px;
  //   padding: 50px;
  // }



  .link-menu {
    text-align: center;
    padding: 23px;
    padding-bottom: 0px;
    text-decoration: none;
    color: grey;

    &:hover {
      text-align: center;
      background-color: $second-blue;
      color: white !important;
      cursor: pointer;
    }

    .link-menu-child {
      color: inherit;
      text-decoration: none;

    }

    .link-menu-divider {
      margin-top: 23px;
      margin-bottom: 5px;
      padding: 0px;
      color: inherit;
    }
  }


}

.link-menu-seguimiento {
  &:hover {
    background-color: $main-orange !important;
  }
}

.link-menu-hidden {
  display: none;

  &:hover {
    display: block;
  }
}

.content-link-menu {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 13px;
}