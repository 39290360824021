.#{$main-prefix}__footer {
  display: flex;
  justify-content: space-between;
  margin: 25px 0!important;
  z-index: 1000;

  .logo-footer {
    height: 56px;
    width: auto;
  }
  
  .navbar-content {
    display: flex;
    flex-direction: column;
  }

  a.link-menu {
    text-decoration: none;
    color: $grey-color
  }
}

@media (max-width: 900px) {
  footer.content-app {
    max-width: 95%;
    width: 95%;

    .logo-footer {
      height: 40px;
      width: auto;
    }
  }
}