@import 'component/index.scss',
        'component/main/index.scss',
        'component/footer/index.scss',
        'component/informacion/index.scss',
        'component/nosotros/index.scss',
        'component/quoter/index.scss',
        'component/retirement/index.scss',
        'component/WspButton/index.scss',
        '_variable.scss',
        'page/Logistica/index.scss',
        'page/Sucursales/index.scss',
        'page/Rrhh/index.scss',
        'component/contacto/index.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grey-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-app {
  width: 1200px;
  margin: 0 auto;
}

h2.expreso-el-vasquito_title {
  font-weight: 300;
  font-size: 35px;
}

select,
input,
textarea {
  padding-left: 5px;
  border-radius: 11px;
  border: 0.5px solid $grey-color;
  color: $grey-color;
}

input[type=submit] {
  height: 30px;
  width: 140px;
  background-color: $main-blue;
  border: none;
  color: white;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  margin: 15px auto 0;
  cursor: pointer;
}


@media (max-width: 900px) {
  .content-app {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    input[type=submit] {
      width: 100%;
      height: 50px;
      font-size: 24px;
    }
  }
}