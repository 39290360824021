.form-description-retiros {
  font-size: 14px;
  width: 100%;
  margin: 25px auto 0;
  text-align: left;
  line-height: 20px;
  color: $grey-color;
}

input.expreso-el-vasquito-retirement--input {
  margin-right: 15px;

  &.name-item {
    width: 160px;
  }

  &.detail-item {
    width: 80px;
  }
}

span.form-error-same-locations {
  background-color: #24195d;
  color: white;
  margin-top: 5px;
  padding: 10px;
  border: black 1px;
  border-radius: 8px;
}

.content-form.destinatario {
  flex-wrap: wrap;
  label {
    display: none;
  }
  input {
    width: 48%;
  }
  select {
    width: 49%;
    height: 38px;
    border-radius: 11px;
    margin: 8px 0;
  }
}

.content-form.remitente {
  flex-wrap: wrap;
  label {
    display: none;
  }
  input {
    width: 48%;
  }
  select {
    width: 49%;
    height: 38px;
    border-radius: 11px;
    margin: 8px 0;
  }
}