// Colors
$main-blue: #24195D;
$second-blue: #17145B;
$main-orange: #EF7D00;
$second-orange: #FFBD59;
$grey-color: #5B5B5B;
$grey-light-color: #eeeeee;
$main-purple: #908FC6;
$second-purple: #E7E6F2;
$blue3: #4131f4;
// Prefixes
$main-prefix: 'expreso-el-vasquito';
$cont: 'content-app';

@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
  font-family: 'Roboto', sans-serif;
}