.content-main-banner {
    background-image: url('../../assets/banner-home-dsk.jpg');
    height: 120vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .#{$main-prefix}__banner {
        padding-top: 0;
        display: flex;
        justify-content: space-between;
    }
    
    .#{$main-prefix}__content-title {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .#{$main-prefix}__form {
        width: 600px;
        height: 610px;
        background: #fff;
        border-radius: 50px;
        padding: 40px;
        overflow: overlay;
        position: relative;

        &:before {
          content: "";
          background: #24195D;
          height: 50px;
          width: 100%;
          left: 0;
          position: absolute;
          top: 0;
      }
    }
    
    .#{$main-prefix}__title {
        margin: 5px 0;
        padding: 0;
        font-size: 36px;
        background: $blue3;
        width: 380px;

        &:first-child {
          width: 335px;
          color: $second-purple;
        }
    }
    p.expreso-el-vasquito__title {
        padding-left: 5px;
        font-weight: 800;
    }

    .#{$main-prefix}__subtitle {
        font-size: 20px;
        background: $main-blue;
        padding-left: 5px;
        color: white;
        font-weight: 400;
        margin-bottom: 10px;
        width: 290px;
        &.first-child {
          margin-top: 50px;
          width: 380px;
        }
    }

    button.#{$main-prefix}__button {
      position: absolute;
      width: 50%;
      background: none;
      border: none;
      color: white;
      top: 0;
      padding: 15px 0;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;

      &.cotizador {
        left: 0;
        
        &.active {
          background: white;
          border-bottom: solid 3px white;
          border-radius: 0 40px 0 0;
          color: $grey-color;
        }
      }

      &.retiro {
        right: 0;

        &.active {
          background: white;
          border-bottom: solid 3px white;
          border-radius: 40px 0 0 0;
          color: $grey-color;
        }
      }
    }

}
.content-main-banner .expreso-el-vasquito__title {
    margin: 5px 0;
    padding: 0;
    font-size: 36px;
    background: $main-blue;
    width: 380px;
    color: white;
}

@media (max-width: 900px) {
  .content-main-banner {
    height: 150vh;
  }

  .content-app.expreso-el-vasquito__banner {
    flex-wrap: wrap;;

    .#{$main-prefix}__content-title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin: 5px auto!important;

      p.expreso-el-vasquito__title {
        font-size: 26px;
      }
    }

    .expreso-el-vasquito__title {
      width: 300px;
      color: #E7E6F2;
      margin: 3px auto!important;
    }

    span.expreso-el-vasquito__subtitle {
      font-size: 18px;
      &.first-child {
        margin-top: 10px;
        width: 350px;
      }
    }

    span.expreso-el-vasquito__subtitle {
      margin: 3px auto;
    }

    .expreso-el-vasquito__form {
      padding: 0;
      margin: 30px auto;
      width: 95%;
    }

    form {
      padding: 15px;
      h5:first-child {
        margin-top: 60px;
      }

      .content-form {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;

        input, 
        input.expreso-el-vasquito--input {
          width: 98%;
          height: 55px;
        }
      }

      select {
        width: 100%;
        height: 55px;
      }

      p.form-description-retiros {
        margin-top: 50px;
      }
    }
  }
}