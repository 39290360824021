.content-app.#{$main-prefix}--sucursales {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;

  select {
    margin-left: 0;
    width: 310px;
  }

  iframe {
    height: 750px;
  }
}

@media (max-width: 900px) {
  .content-app.expreso-el-vasquito--sucursales {
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;

    h2.expreso-el-vasquito_title {
      margin: 0 auto;
      text-align: center;
    }

    select {
      display: block;
      margin: 20px auto;
    }

    .card_sucursales {
      width: 80%;
      margin: 0 auto;
    }

    .expreso-el-vasquito--iframe {
      width: 100%;
      iframe {
        width: 94%;
        margin: 30px auto;
        display: block;
      }
    }
  }
}