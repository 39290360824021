.#{$main-prefix}--form-title {
  font-size: 18px;
  margin: 20px 0 5px;
  font-weight: 300;
  color: $grey-color;
  display: block;
  width: 100%;
  &.subtitle {
    display: inline-block;
    width: 37%;
    font-size: 15px;
    margin: 10px 0;
    &.seguro-propio {
      width: 20%;
    }
  }
}

.content-form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

input.expreso-el-vasquito--input {
  width: 31%;
  &.box {
    width: 4%;
    height: 20px;
    margin-right: 60px;
  }
}

.content-form.remitente {
  flex-wrap: wrap;
  label {
    display: none;
  }
  input.expreso-el-vasquito--input {
    width: 48%;
  }
  select {
    width: 49%;
    height: 38px;
    border-radius: 11px;
    margin: 8px 0;
  }
}

.content-form.destinatario {
  flex-wrap: wrap;
  label {
    display: none;
  }
  input.expreso-el-vasquito--input {
    width: 48%;
  }
  select {
    width: 49%;
    height: 38px;
    border-radius: 11px;
    margin: 8px 0;
  }
}


p.form-description-valores {
  font-size: 12px;
  width: 100%;
  margin: 10px auto;
  text-align: center;
}

.content-form.upgrade-items {
  flex-wrap: wrap;
  justify-content: start;
  
  p {
    color: $grey-color;
    font-size: 15px;
    font-weight: 400;
    margin-right: 10px;
    color: $main-orange;
  }

  .#{$main-prefix}--button {
    background: $main-orange;
    text-decoration: underline;
    color: #ffffff;
    cursor: pointer;
    border-radius: 13px;
    margin-top: 7px;
    height: 38px;
    width: 146px;
    border: none;
  }
  
  input.expreso-el-vasquito--input {
    margin-right: 15px;

    &.name-item {
      width: 160px;
    }
    
    &.detail-item {
      width: 80px;
    }
  }

  input.expreso-el-vasquito--input.detail-item.valor-declarado {
    width: 125px;
  }

  .#{$main-prefix}--button.detail-item{
    color: $main-orange;
    background: none;
    border: 1px solid $main-orange;
    border-radius: 100%;
    height: 23px;
    margin: 10px 15px 0 0;
    width: 23px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background: $main-orange;
      color: white;
    }
  }
}

@media (max-width: 900px) {
  .expreso-el-vasquito__form {
    a.expreso-el-vasquito--content-button {
      width: 100%!important;
      button.expreso-el-vasquito--button {
        background: none;
        border: 1px solid $main-orange;
        color: $main-orange;
        width: 100%;
        height: 50px;
        font-size: 24px;
      }
    }
    p.form-description-valores {
      margin: 35px auto 0px;
    }
    input.expreso-el-vasquito--input.box {
      margin-right: 45px;
    }
  }
}