.content-app.#{$main-prefix}_recursos-humanos {
  margin: 100px auto!important;
  height: calc(90vh - 200px);

  h2.#{$main-prefix}_title {
    margin: 20px 0 30px;
    text-align: center;
    font-weight: 300;
    font-size: 24px;
  }
}
