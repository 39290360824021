.a-app-seguimiento {
  position: fixed;
  top: auto;
  bottom: 85px;
  right: 15px;
  color: white;
  background-color: #4dc247;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  border-radius: 50%;
  padding: 5px 8px;
}

.img-app-seguimiento {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  fill: white;
  vertical-align: middle;
}

.button-show {
  display: block;
}

.button-mobile {
  display: block;
  @media (max-width: 900px) {
    display: none;
  }
}

.button-hide {
  display: none;
}

