.#{$main-prefix}__image-svg {
    width: 90px;
    height: auto;
} 
.content-app.expreso-el-vasquito__nosotros-left {
    display: flex;
    flex-wrap: wrap;
    margin-top: 59px;
    justify-content: center;
    align-items: center;
}

.card-informacion {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 225px;
  height: 240px;
  box-shadow: 0 0 3px 0 ;
  border-radius: 30px;
  text-align: center;
  margin: 20px;
  background-color: white;
}

.button_informacion{
    background-color: $main-blue;
    border: none;
    color: white;
    padding: 10px 72px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    border-radius: 12px;
    margin: 30px;
}

.expreso-el-vasquito__informacion {
    background: url('./asset/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.expreso-el-vasquito__informacion {
  h2.expreso-el-vasquito_title {
    text-align: center;
    margin-bottom: 100px;
  }
}

.card-informacion-text {
  width: 85%;
}

.expreso-el-vasquito-content-buttons-sucursales {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

@media (max-width: 900px) {
  .expreso-el-vasquito__informacion {
    background: none;
    h2 {
      margin-bottom: 0!important;
    }

    .card-informacion {
      width: 160px;
      height: 225px;
      margin: 5px;
    }

    .card-informacion-text {
      width: 85%;
      font-size: 14px;
    }

    .expreso-el-vasquito-content-buttons-sucursales {
      max-width: 100%;
      width: 90%;
      flex-wrap: wrap;
      margin: 30px auto 0;
      height: 150px;

      a.button_informacion {
        width: 250px;
        text-align: center;
        height: 30px;
        font-size: 24px;
        margin: 0;
      }
    }
  }
}