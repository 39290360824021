.#{$main-prefix}_logistica {
  margin: 130px auto!important;

  .#{$main-prefix}_content-text-iframe {
    display: flex;
    justify-content: space-between;
  }
  .expreso-el-vasquito_imgtitle {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 350px;
    display:block;
    margin-bottom: 60px;
  }
  .#{$main-prefix}_content-text {
    display: block;
    p.#{$main-prefix}_text {
      margin-top: 10px;
      text-justify: revert;
    }
  }
  .expreso-el-vasquito_imgalianza {
    float: left;
    max-width: 300px !important;
    max-height: 200px !important;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.expreso-el-vasquito_alianza_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.expreso-el-vasquito_alianza_container_img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 120px;
  text-align: center;
  margin: 20px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: white;
}

@media (max-width: 900px) {
  .expreso-el-vasquito_content-text-iframe {
    flex-wrap: wrap;
    .expreso-el-vasquito_content-text {
      width: 95%;
      margin: 0 auto;
    }

    iframe {
      width: 95%;
      display: block;
      margin: 0 auto;
    }
  }
}